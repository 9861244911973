var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            inline: "",
            "label-position": "right",
            "label-width": "20px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "往来单位" },
                  model: {
                    value: _vm.form.supp_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "supp_id", $$v)
                    },
                    expression: "form.supp_id",
                  },
                },
                _vm._l(_vm.danweiSelect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.supp_name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "类型" },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                _vm._l(_vm.leixingSelect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "-",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleQeury } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-button",
                { attrs: { type: "warning" }, on: { click: _vm.handleClear } },
                [_vm._v("清空")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-button", { on: { click: _vm.handleExport } }, [
                _vm._v("导出"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticStyle: { padding: "10px" } }, [
        _c("span", { staticStyle: { "margin-right": "20px" } }, [
          _vm._v("期初余额: " + _vm._s(_vm.info.begin)),
        ]),
        _c("span", { staticStyle: { "margin-right": "20px" } }, [
          _vm._v("应付合计: " + _vm._s(_vm.info.should_pay)),
        ]),
        _c("span", { staticStyle: { "margin-right": "20px" } }, [
          _vm._v("已付合计: " + _vm._s(_vm.info.receive_pay)),
        ]),
        _c("span", { staticStyle: { "margin-right": "20px" } }, [
          _vm._v(" 应收合计: " + _vm._s(_vm.info.should_collection) + " "),
        ]),
        _c("span", { staticStyle: { "margin-right": "20px" } }, [
          _vm._v(" 已收合计: " + _vm._s(_vm.info.receive_collection) + " "),
        ]),
        _c("span", { staticStyle: { "margin-right": "20px" } }, [
          _vm._v("期末余额: " + _vm._s(_vm.info.balance)),
        ]),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { border: "", stripe: "", data: _vm.list },
        },
        [
          _c("el-table-column", { attrs: { type: "index" } }),
          _vm._l(_vm.columns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                prop: item.prop,
                label: item.label,
                width: item.width,
                align: "center",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.prop == "bill_code"
                          ? _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "underline",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDetail(row)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(row.bill_code) + " ")]
                              ),
                            ])
                          : _c("div", [
                              _vm._v(" " + _vm._s(row[item.prop]) + " "),
                            ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.form.pageNo,
          layout: _vm.layout,
          "page-size": _vm.form.pageSize,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("CT", { ref: "CT" }),
      _c("CG", { ref: "CG" }),
      _c("FK", { ref: "FK" }),
      _c("PZ", { ref: "PZ" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }